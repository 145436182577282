<script setup>
import Button from '@/Components/Button/Button.vue';
import Card from '@/Components/Flexer/Card.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { objectMap } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    flexer: Object,
});

const form = useForm({
    flexer_id: props.flexer.id,
    reason: '',
});

const sendForm = () => {
    form.post(route('staff.blocked-flexers.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            form.errors = objectMap(errors, (error) => [error]);
        },
    });
};
</script>

<template>
    <StaffLayout :title="$t('Fl@xr')">
        <template #sidebar>
            <Card :flexer="flexer" :with-relation-buttons="false" />
        </template>

        <template #default>
            <FormKit type="form" id="company-form" @submit="sendForm" :actions="false">
                <FormKit
                    type="textarea"
                    name="reason"
                    :label="$t('Reason for blocking')"
                    v-model="form.reason"
                    :errors="form.errors['reason']"
                    :value="form.reason"
                />

                <Button danger :processing="form.processing" :recentlySuccessful="form.recentlySuccessful">
                    <div class="flex items-center gap-2">
                        <img class="w-4" src="/icon/block-white.svg" alt="" />
                        <div class="w-full">
                            {{ $t('Block') }}
                        </div>
                    </div>
                </Button>
            </FormKit>
        </template>
    </StaffLayout>
</template>
